import { Component } from '@angular/core';
import { MatCardContent } from '@angular/material/card';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'sb-go-cardless',
  standalone: true,
  imports: [MatCardContent, MatProgressSpinner],
  templateUrl: './go-cardless.component.html',
  styles: ``,
})
export class GoCardlessComponent {}
